import React, { useEffect, useState } from "react";
import axios from "axios";

export default function SurveyResponsePage({ match }) {
  const { id } = match.params;
  const [error, setError] = useState(null);
  const [surveyResponse, setSurveyResponse] = useState(null);

  useEffect(() => {
    async function fetchQuestionnaireResponse() {
      try {
        const { data } = await axios.get(
          `https://radon-api.herokuapp.com/api/v1/survey_responses/${id}`
        );

        setSurveyResponse(data);
      } catch (error) {
        console.error("Error fetching questionnaire response:", error);
        setError("Error fetching questionnaire's data");
      }
    }

    fetchQuestionnaireResponse();
  }, [id]);

  if (error) return <div>{error}</div>;
  if (!surveyResponse) return <div>Loading...</div>;

  return (
    <main>
      <div>Respondent Name: {surveyResponse.full_name}</div>

      {surveyResponse.radon_kits_google_form_links && (
        <div>
          <div>Form Type: {surveyResponse.form_type}</div>
          <div>
            Assigned from:{" "}
            <a
              href={surveyResponse.radon_kits_google_form_links}
              target="_blank"
              rel="noopener noreferrer"
            >
              {surveyResponse.radon_kits_google_form_links}
            </a>
          </div>
        </div>
      )}

      <div class="ui bulleted list">
        {
          surveyResponse.radon_tested_within_two_years &&
          <div class="item">
            <div><strong>Question: </strong>Have you tested your place of residence for radon within the past two years?</div>
            <div><strong>Answer: </strong>{surveyResponse.radon_tested_within_two_years}</div>
          </div>
        }
        {
          surveyResponse.birth_year &&
          <div class="item">
            <div><strong>Question: </strong>Please enter your birth year (e.g., 1997)</div>
            <div><strong>Answer: </strong>{surveyResponse.birth_year}</div>
          </div>
        }
        {
          surveyResponse.gender &&
          <div class="item">
            <div><strong>Question: </strong>What is your gender?</div>
            <div><strong>Answer: </strong>{surveyResponse.gender}</div>
          </div>
        }
        {
          surveyResponse.current_housing_situation &&
          <div class="item">
            <div><strong>Question: </strong>Which of the following best describes your current housing situation?</div>
            <div><strong>Answer: </strong>{surveyResponse.current_housing_situation}</div>
          </div>
        }
        {
          surveyResponse.city_of_residence &&
          <div class="item">
            <div><strong>Question: </strong>In what city do you currently live?</div>
            <div><strong>Answer: </strong>{surveyResponse.city_of_residence}</div>
          </div>
        }
        {
          surveyResponse.racial_background &&
          <div class="item">
            <div><strong>Question: </strong>What is your racial/ethnic background?</div>
            <div><strong>Answer: </strong>{surveyResponse.racial_background}</div>
          </div>
        }
        {
          surveyResponse.highest_level_of_schooling &&
          <div class="item">
            <div><strong>Question: </strong>What is the highest grade or level of schooling you completed?</div>
            <div><strong>Answer: </strong>{surveyResponse.highest_level_of_schooling}</div>
          </div>
        }
        {
          surveyResponse.family_income_category &&
          <div class="item">
            <div><strong>Question: </strong>Before taxes, which of the following categories did your total family income fall into last year?</div>
            <div><strong>Answer: </strong>{surveyResponse.family_income_category}</div>
          </div>
        }
        {
          surveyResponse.tobacco_use_frequency &&
          <div class="item">
            <div><strong>Question: </strong>Do you currently smoke/use tobacco?</div>
            <div><strong>Answer: </strong>{surveyResponse.tobacco_use_frequency}</div>
          </div>
        }
        {
          surveyResponse.cigarettes_use_frequency &&
          <div class="item">
            <div><strong>Question: </strong>Do you currently smoke/use e-cigarettes?</div>
            <div><strong>Answer: </strong>{surveyResponse.cigarettes_use_frequency}</div>
          </div>
        }
        {
          surveyResponse.full_name &&
          <div class="item">
            <div><strong>Question: </strong>Please enter your full name. This information is collected to keep track of your research participation and send Amazon gift cards.</div>
            <div><strong>Answer: </strong>{surveyResponse.full_name}</div>
          </div>
        }
        {
          surveyResponse.email_address &&
          <div class="item">
            <div><strong>Question: </strong>Please enter your email address. This information is collected to keep track of your research participation and send Amazon gift cards.</div>
            <div><strong>Answer: </strong>{surveyResponse.email_address}</div>
          </div>
        }
        {
          surveyResponse.und_student_or_employee &&
          <div class="item">
            <div><strong>Question: </strong>Are you a UND student or employee?</div>
            <div><strong>Answer: </strong>{surveyResponse.und_student_or_employee}</div>
          </div>
        }
        {
          surveyResponse.und_student_or_employee_code &&
          <div class="item">
            <div><strong>Question: </strong>Please enter your student ID number or EMPLID. This information is required for UND to send Amazon gift cards.</div>
            <div><strong>Answer: </strong>{surveyResponse.und_student_or_employee_code}</div>
          </div>
        }
        {
          surveyResponse.und_affiliation_at_the_moment &&
          <div class="item">
            <div><strong>Question: </strong>Are you a UND student employee at the moment? This information is required for UND to send Amazon gift cards.</div>
            <div><strong>Answer: </strong>{surveyResponse.und_affiliation_at_the_moment}</div>
          </div>
        }
        {
          surveyResponse.tax_status &&
          <div class="item">
            <div><strong>Question: </strong>What is your tax status? This information is required for UND to send Amazon gift cards.</div>
            <div><strong>Answer: </strong>{surveyResponse.tax_status}</div>
          </div>
        }
        {
          surveyResponse.referrer_name &&
          <div class="item">
            <div><strong>Question: </strong>Did someone refer you to complete this survey? If yes, please provide their name.</div>
            <div><strong>Answer: </strong>{surveyResponse.referrer_name}</div>
          </div>
        }
      </div>
    </main>
  );
}
